/**
 * Takes a template string like "Do something with {{property}}" and an object like { property: value }
 * And fills in the template string with object proeprty values
 *
 * @param template A template string containing {{property}} placeholders
 * @param data  An object with property values to fill in the template
 * @returns The template string with the placeholders filled in
 */
export function fillTemplate(template: Nullish<string>, data: Nullish<object>) {
    if (!template) return '';
    if (!data) return '';
    if (!template.includes('{{')) return template;

    return template.replace(/{{(\w+)}}/g, (_, key) => {
        // @ts-expect-error data is an object
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const value = data[key];

        if (!value) return '';
        if (typeof value === 'string') {
            const isNumber = Number.isFinite(value);
            if (isNumber) return String(value);

            const isDateLike = !isNaN(Date.parse(value));
            if (isDateLike) return new Date(value).toLocaleDateString();
        }

        return String(value);
    });
}
